import React from 'react';
import { Helmet } from 'react-helmet';

function Help_Seo() {
  return (
    <div>
      <Helmet>
                <title>Help | PayQuick</title>
                <meta name="keywords" content="" />
                <meta name="description" content="" />
                <meta property="og:title" content="Help | PayQuick" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
                <meta property="og:url" content="https://pay-quik.co.uk/help" />
                <meta name="twitter:title" content="Help | PayQuick" />
                <meta name="twitter:description" content="" />
                <meta name="twitter:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
            </Helmet>
    </div>
  )
}

export default Help_Seo
