import React from 'react'

function ConsumerDutyPolicySeo() {
    return (
        <div>
            <title>Consumer Duty Policy | PayQuick</title>
            <meta name="keywords" content="" />
            <meta name="description" content="" />
            <meta property="og:title" content="Consumer Duty Policy | PayQuick" />
            <meta property="og:description" content="" />
            <meta property="og:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
            <meta property="og:url" content="https://pay-quik.co.uk/consumer-duty-policy" />
            <meta name="twitter:title" content="Consumer Duty Policy | PayQuick" />
            <meta name="twitter:description" content="" />
            <meta name="twitter:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
        </div>
    )
}

export default ConsumerDutyPolicySeo
