import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Preloader from './Components/Preloader';
import Error_Seo from './SEO/Error_Seo';

function Error() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an asynchronous operation (e.g., fetching data)
        setTimeout(() => {
            setIsLoading(false);
        }, 500); // Simulating a 1-second delay
        // Sticky Menu and Scroll-to-Top
        const handleScroll = () => {
            const scroll = window.scrollY;
            const stickyHeader = document.getElementById('sticky-header');
            const scrollToTarget = document.querySelector('.scroll-to-target');

            if (scroll < 245) {
                stickyHeader?.classList.remove('sticky-menu');
                scrollToTarget?.classList.remove('open');
            } else {
                stickyHeader?.classList.add('sticky-menu');
                scrollToTarget?.classList.add('open');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // thmSwiperInit definition
        const thmSwiperInit = () => {
            console.log('Swiper initialized');
        };

        thmSwiperInit();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                // Your actual content when not loading
                <div>
                    <Error_Seo />
                    {/* back to top start */}
                    <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <i className="fa-solid fa-angles-up" />
                    </button>
                    <div className="">
                       
                        {/* Error section start */}
                        <section className="error-sec sec-ptb">
                            <div className="container">
                                <div className="row">
                                    <div className="offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                                        <div className="error-content text-center">
                                            <img src="assets/images/error/404.jpg" alt="error" />
                                            <NavLink to='/' className="thm-btn mt-5">
                                                <span className="txt">Back To Home</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Error section end */}

                        {/* app section start */}
                    </div>
                </div>
            )}

        </div>

    )
}

export default Error
