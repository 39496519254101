import React, { useEffect, useState } from 'react';
import '../../css/steps.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Preloader from '../../Components/Preloader';
import Onboarding_FAQ_Seo from '../../SEO/Onboarding_FAQ_Seo';

function Onboarding_FAQs() {
    const [isLoading, setIsLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(null); // State to keep track of the active accordion

    useEffect(() => {
        // Simulate an asynchronous operation (e.g., fetching data)
        setTimeout(() => {
            setIsLoading(false);
        }, 500); // Simulating a 0.5-second delay

        // Sticky Menu and Scroll-to-Top
        const handleScroll = () => {
            const scroll = window.scrollY;
            const stickyHeader = document.getElementById('sticky-header');
            const scrollToTarget = document.querySelector('.scroll-to-target');

            if (scroll < 245) {
                stickyHeader?.classList.remove('sticky-menu');
                scrollToTarget?.classList.remove('open');
            } else {
                stickyHeader?.classList.add('sticky-menu');
                scrollToTarget?.classList.add('open');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // thmSwiperInit definition
        const thmSwiperInit = () => {
            console.log('Swiper initialized');
        };

        thmSwiperInit();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleAccordionClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                <div>
                    <Onboarding_FAQ_Seo/>
                    {/* back to top start */}
                    <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <i className="fa-solid fa-angles-up" />
                    </button>
                    <div className="">
                        <Header />
                        <section id="site-breadcumb" style={{ backgroundImage: 'url("assets/images/breadcumb/breadcumb-bd.svg")' }}>
                            <div className="site-breadcumb-wrapper">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="breadcumb-content">
                                                <h1 className="breadcumb-title">Onboarding FAQs</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Onboarding FAQs section start */}
                        <section className="faq-sec sec-ptb">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div id="accordion" className="accordion-container wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                            {[
                                                "1. I'm getting a black blank screen. What should I do?",
                                                "2. I'm getting error message saying, 'Wrong ID document or something went wrong...'"
                                            ].map((title, index) => (
                                                <div key={index}>
                                                    <h4
                                                        className={`accordion-title ${activeIndex === index ? 'open' : ''}`}
                                                        onClick={() => handleAccordionClick(index)}
                                                    >
                                                        {title}
                                                    </h4>
                                                    <div className="accordion-content mb-3" style={{ display: activeIndex === index ? 'block' : 'none' }}>
                                                        {index === 0 && (
                                                            <div>
                                                                <div className="text" >
                                                                    <p>Does your screen look something like this?</p>
                                                                    <div className="row" >
                                                                        <div className="col-6 col-md-5 col-lg-2" >
                                                                            <div className="cardcss" >
                                                                                <img className="card-img-top" src="assets/images/photo/blankscreen1.png" alt="I'm getting a black blank screen. What should I do?" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 col-md-5 col-lg-2" >
                                                                            <div className="cardcss" >
                                                                                <img className="card-img-top" src="assets/images/photo/blankscreen2.png" alt="I'm getting a black blank screen. What should I do?" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p>
                                                                        This means the CAMERA access to your default BROWSER is denied. Please follow the steps below to Allow camera access.
                                                                    </p>
                                                                    <ul className="nav nav-pills nav-justified flex-column flex-sm-row style1" id="pills-tab" role="tablist">
                                                                        <li className="nav-item" role="presentation">
                                                                            <a className="nav-link rounded active" id="pills-cloud-tab" data-bs-toggle="pill" href="#iOS" role="tab" aria-controls="pills-cloud" aria-selected="true" >
                                                                                <div className="text-center py-2" >
                                                                                    <h6 className="mb-0">iOS</h6>
                                                                                </div>
                                                                            </a>
                                                                            {/*end nav link*/}
                                                                        </li>
                                                                        {/*end nav item*/}
                                                                        <li className="nav-item" role="presentation">
                                                                            <a className="nav-link rounded" id="pills-smart-tab" data-bs-toggle="pill" href="#android" role="tab" aria-controls="pills-smart" aria-selected="false" tabIndex={-1} >
                                                                                <div className="text-center py-2" >
                                                                                    <h6 className="mb-0">Android</h6>
                                                                                </div>
                                                                            </a>
                                                                            {/*end nav link*/}
                                                                        </li>
                                                                        {/*end nav item*/}
                                                                    </ul>
                                                                    {/*end nav pills*/}
                                                                    <div className="row" >
                                                                        <div className="col-12 mt-1 pt-1" >
                                                                            <div className="tab-content" id="pills-tabContent" >
                                                                                <div className="tab-pane fade show active" id="iOS" role="tabpanel" aria-labelledby="pills-cloud-tab" >
                                                                                    <div className="row align-items-center" >
                                                                                        <div className="col-md-12 mt-1 mt-sm-0 pt-0 pt-sm-0" >
                                                                                            <div className="full mb-1" >
                                                                                                <div className="row wow fadeInUp animated animated animated" data-wow-delay="0.4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }} >
                                                                                                    <div className="col-12 col-md-12 col-xs-12 offset-xs-1" >
                                                                                                        <p>i. Go to Settings → Safari</p>
                                                                                                        <div className="row" >
                                                                                                            <div className="col-12 col-md-5 col-lg-3" >
                                                                                                                <div className="cardcss" >
                                                                                                                    <img className="card-img-top" src="assets/images/photo/apple1.png" alt="Step1" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <p>ii. Scroll to find "Camera" under SETTINGS FOR WEBSITES section</p>
                                                                                                        <div className="row" >
                                                                                                            <div className="col-12 col-md-5 col-lg-3" >
                                                                                                                <div className="cardcss" >
                                                                                                                    <img className="card-img-top" src="assets/images/photo/apple2.png" alt="Step2" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <p>iii. Tap on Camera option and you will be navigated to CAMERA ACCESS ON ALL WEBSITES. Tap on "Allow" option.</p>
                                                                                                        <div className="row" >
                                                                                                            <div className="col-12 col-md-5 col-lg-3" >
                                                                                                                <div className="cardcss" >
                                                                                                                    <img className="card-img-top" src="assets/images/photo/apple3.png" alt="Step1" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/*end col*/}
                                                                                    </div>
                                                                                    {/*end row*/}
                                                                                </div>
                                                                                {/*end teb pane*/}
                                                                                <div className="tab-pane fade" id="android" role="tabpanel" aria-labelledby="pills-smart-tab" >
                                                                                    <div className="row align-items-center" >
                                                                                        <div className="col-md-12 mt-1 mt-sm-0 pt-0 pt-sm-0" >
                                                                                            <p>i. Go to Settings and tap on Apps</p>
                                                                                            <div className="row" >
                                                                                                <div className="col-12 col-md-5 col-lg-3" >
                                                                                                    <div className="cardcss" >
                                                                                                        <img className="card-img-top" src="assets/images/photo/ad1.png" alt="Step1" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <p>ii. Scroll to find your default browser app. Mostly it is Google Chrome for Android device, unless explicitly changed.</p>
                                                                                            <div className="row" >
                                                                                                <div className="col-12 col-md-5 col-lg-3" >
                                                                                                    <div className="cardcss" >
                                                                                                        <img className="card-img-top" src="assets/images/photo/ad2.png" alt="Step2" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <p>iii. Chrome → Permissions will show you what is enabled. Camera permission need to be enabled here. </p>
                                                                                            <div className="row" >
                                                                                                <div className="col-12 col-md-5 col-lg-3" >
                                                                                                    <div className="cardcss" >
                                                                                                        <img className="card-img-top" src="assets/images/photo/ad3.png" alt="Step3" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <p>iv. App permissions will show what is allowed and what's denied.</p>
                                                                                            <div className="row" >
                                                                                                <div className="col-12 col-md-5 col-lg-3" >
                                                                                                    <div className="cardcss" >
                                                                                                        <img className="card-img-top" src="assets/images/photo/ad4.png" alt="Step4" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <p>v. Tap on Camera to Allow camera permission to Chrome.</p>
                                                                                            <div className="row" >
                                                                                                <div className="col-12 col-md-5 col-lg-3" >
                                                                                                    <div className="cardcss" >
                                                                                                        <img className="card-img-top" src="assets/images/photo/ad5.png" alt="Step5" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/*end col*/}
                                                                                    </div>
                                                                                    {/*end row*/}
                                                                                </div>
                                                                                {/*end teb pane*/}
                                                                            </div>
                                                                            {/*end tab content*/}
                                                                        </div>
                                                                        {/*end col*/}
                                                                    </div>
                                                                    {/*end row*/}
                                                                </div>

                                                            </div>
                                                        )}
                                                        {index === 1 && (
                                                            <div>
                                                                <div className="text">
                                                                    <p>Does your screen look something like this?</p>
                                                                    <div className="row">
                                                                        <div className="col-6 col-md-5 col-lg-3">
                                                                            <div className="cardcss">
                                                                                <img className="card-img-top" src="assets/images/photo/ID Upload failure.png" alt="Wrong ID document or something went wrong." />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p className="mt-2">
                                                                        This means the ID you uploaded is not supported or was having glare or blur while uploading. This may have resulted us in not been able to capture ID details. Please give it another try or contact our support team.
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* Onboarding FAQs section end */}
                        <Footer />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Onboarding_FAQs
