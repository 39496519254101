import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

function Header() {
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
   const [dropdownOpen, setDropdownOpen] = useState({});
   const [showCookiesConsent, setShowCookiesConsent] = useState(true);
   
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted) {
      setShowCookiesConsent(false);
    }
    
    if (isMobileMenuVisible) {
      document.body.classList.add('mobile-menu-visible');
    } else {
      document.body.classList.remove('mobile-menu-visible');
    }

    return () => {
      document.body.classList.remove('mobile-menu-visible');
    };
  }, [isMobileMenuVisible]);

  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowCookiesConsent(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!isMobileMenuVisible);
  };

  const toggleDropdown = (index) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <div>
      {/*Start Main Header One */}
      <header className="main-header main-header-one">
        <div id="sticky-header" className="menu-area">
          <div className="main-header-one__outer">
            <div className="main-header-one__right">
              <div className="container">
                <div className="menu-area__inner">
                  <div className="mobile-nav-toggler align-items-center" onClick={toggleMobileMenu}>
                    <div className="site-ligo">
                      <NavLink to="/">
                        <img src="assets/images/logo/logo1.png" alt="site-logo" />
                      </NavLink>
                    </div>
                    <i className="fas fa-bars" />
                  </div>
                  <div className="menu-wrap">
                    <nav className="menu-nav">
                      <div className="main-header-one__inner">
                        <div className="main-header-one__top">
                          <div className="main-header-one__top-pattern" />
                          <div className="main-header-one__top-inner">
                            <div className="main-header-one__top-left">
                              <div className="header-contact-info">
                                <ul>
                                  <li>
                                    <div className="icon-box">
                                      <i className="flaticon-gmail-logo" />
                                    </div>
                                    <p>
                                      <NavLink to="mailto:info@pay-quik.com">info@pay-quik.com</NavLink>
                                    </p>
                                  </li>
                                  <li>
                                    <div className="icon-box">
                                      <i className="flaticon-call" />
                                    </div>
                                    <p>
                                      <NavLink to="tel:447766431049">+44 7766 431 049</NavLink>
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="main-header-one__top-right">
                              <div className="inner">
                                <div className="header-social-links">
                                  <ul>
                                    <li>
                                      <NavLink to="#"><i className="fa-brands fa-facebook-f" /></NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="#"><i className="fa-brands fa-youtube" /></NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="#"><i className="fa-brands fa-x-twitter" /></NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="#"><i className="fa-brands fa-instagram" /></NavLink>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="main-header-one__bottom">
                          <div className="main-header-one__bottom-left d-flex">
                            <div className="site-ligo d-flex align-items-center">
                              <NavLink to="/">
                                <img src="assets/images/logo/site-logo.png" alt="site-logo" />
                              </NavLink>
                            </div>
                            <div className="navbar-wrap main-menu">
                              <ul className="navigation">
                                <li className="">
                                  <NavLink to="/About">About Us</NavLink>
                                </li>   
                                <li className="menu-item-has-children">
                                  <NavLink to="/">Money Transfer</NavLink>
                                  <ul className="sub-menu">
                                    <li>
                                      <NavLink to="/money_transfer_to_kenya">Money Transfer to Kenya</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_uganda">Money Transfer to Uganda</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_somalia">Money Transfer to Somalia</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_ethiopia">Money Transfer to Ethiopia</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_United%20States">Money Transfer to UAE</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_turkey">Money Transfer to Turkey</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_australia">Money Transfer to Australia</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_usa">Money Transfer to USA</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_djibouti">Money Transfer to Djibouti</NavLink>
                                    </li>
                                  </ul>
                                </li>

                                <li className="">
                                  <NavLink to="/help">Help</NavLink>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="main-header-one__bottom-right">
                            <NavLink className="a" to="https://pay-quik.co.uk/remit/sign-up" target='_blank'>Signup</NavLink>
                            <div className="btn-box">
                              <NavLink className="thm-btn" to="https://pay-quik.co.uk/remit/" target='_blank'>
                                <span className="txt">Login</span>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
                {/* Mobile Menu */}
                <div className={`mobile-menu ${isMobileMenuVisible ? 'visible' : ''}`}>
                  <nav className="menu-box">
                    <div className={"close-btn"} onClick={toggleMobileMenu}>
                      <i className="fas fa-times" />
                    </div>
                    <div className="nav-logo">
                      <NavLink to="/">
                        <img src="assets/images/logo/site-logo.png" alt="Logo" />
                      </NavLink>
                    </div>
                    <div className="menu-outer">
                      <ul class="navigation">
                        <li className="">
                          <NavLink to="/about">About Us</NavLink>
                        </li>

                        <li className={`menu-item-has-children ${dropdownOpen[0] ? 'open' : ''}`}>
                                  <NavLink to="/" onClick={() => toggleDropdown(0)}>Money Transfer</NavLink>
                                  <ul className="sub-menu" style={{ display: dropdownOpen[0] ? 'block' : 'none' }}>
                                  <li>
                                      <NavLink to="/money_transfer_to_kenya">Money Transfer to Kenya</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_uganda">Money Transfer to Uganda</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_somalia">Money Transfer to Somalia</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_ethiopia">Money Transfer to Ethiopia</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_uae">Money Transfer to UAE</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_turkey">Money Transfer to Turkey</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_australia">Money Transfer to Australia</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_usa">Money Transfer to USA</NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/money_transfer_to_djibouti">Money Transfer to Djibouti</NavLink>
                                    </li>
                                  </ul>
                                  <div className="dropdown-btn" onClick={() => toggleDropdown(0)}>
                                    <span className="fas fa-angle-down"></span>
                                  </div>
                                </li>
                        <li className="">
                          <NavLink to="/help">Help</NavLink>
                        </li>
                      </ul>
                      <div className="btn-box text-center">
                        <hr />
                        <NavLink className="a" to="https://pay-quik.co.uk/remit/sign-up" target='_blank'>Signup</NavLink>&emsp;
                        <NavLink className="thm-btn btn-block" to="https://pay-quik.co.uk/remit/" target='_blank'>
                          <span className="txt">Login</span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="contact-info">
                      <div className="icon-box">
                        <i className="flaticon-call" />
                      </div>
                      <p>
                        <NavLink to="tel:447766431049">+44 7766 431 049</NavLink>
                      </p>
                    </div>
                    <div className="social-links">
                      <ul className="clearfix list-wrap">
                        <li>
                          <NavLink to="/">
                            <i className="fab fa-facebook-f" />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/">
                            <i className="fab fa-x-twitter" />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/">
                            <i className="fab fa-instagram" />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/">
                            <i className="fab fa-youtube" />
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" onClick={toggleMobileMenu} />
                {/* End Mobile Menu */}
              </div>
            </div>
          </div>
        </div>
      </header>
      {showCookiesConsent && (
        <div className="wrapper">
        <div className="d-flex align-items-center justify-content-start gap-2">
        <img src="../images/cookies.png" alt="cookies consent" />
        <h5>Cookies Consent</h5>
        </div>
          <div className="content">
        
            <p>
              We use cookies to improve your browsing experience and for marketing purposes.
            </p>
            <div >
              <button className="thm-btn" onClick={acceptCookies}>
                <span className='txt'>I understand</span>
              </button>
              <NavLink to="/privacy-policy" className="item ms-3" style={{textDecoration: 'underline'}}>
                Privacy Policy
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Header
