import React from 'react'

function Loading() {
  return (
    <div>
<div id="preloader">
  <div id="loader" />
</div>
    </div>
  )
}

export default Loading
