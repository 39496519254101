import React from 'react';
import { Helmet } from 'react-helmet';

function Livenesscheck_Seo() {
    return (
        <div>
            <Helmet>
                <title>Onboarding | PayQuick</title>
                <meta name="keywords" content="" />
                <meta name="description" content="" />
                <meta property="og:title" content="Onboarding | PayQuick" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
                <meta property="og:url" content="https://pay-quik.co.uk/onboarding" />
                <meta name="twitter:title" content="Onboarding | PayQuick" />
                <meta name="twitter:description" content="" />
                <meta name="twitter:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
            </Helmet>
        </div>
    )
}

export default Livenesscheck_Seo
