import React, { useEffect, useState } from 'react';
import '../../css/steps.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Preloader from '../../Components/Preloader';
import { NavLink } from 'react-router-dom';
import Onboarding_Seo from '../../SEO/Onboarding_Seo';

function Onboarding() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an asynchronous operation (e.g., fetching data)
        setTimeout(() => {
            setIsLoading(false);
        }, 500); // Simulating a 0.5-second delay

        // Sticky Menu and Scroll-to-Top
        const handleScroll = () => {
            const scroll = window.scrollY;
            const stickyHeader = document.getElementById('sticky-header');
            const scrollToTarget = document.querySelector('.scroll-to-target');

            if (scroll < 245) {
                stickyHeader?.classList.remove('sticky-menu');
                scrollToTarget?.classList.remove('open');
            } else {
                stickyHeader?.classList.add('sticky-menu');
                scrollToTarget?.classList.add('open');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // thmSwiperInit definition
        const thmSwiperInit = () => {
            console.log('Swiper initialized');
        };

        thmSwiperInit();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                <div>
                    <Onboarding_Seo/>
                    {/* back to top start */}
                    <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <i className="fa-solid fa-angles-up" />
                    </button>
                    <div className="">
                        <Header />
                        <section id="site-breadcumb" style={{ backgroundImage: 'url("assets/images/breadcumb/breadcumb-bd.svg")' }}>
                            <div className="site-breadcumb-wrapper">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="breadcumb-content">
                                                <h1 className="breadcumb-title">Onboarding</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Onboarding section start */}
                        <section className="sec-ptb">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                                        <div className="card px-0 pb-0 mb-3">
                                            <h2 className="mb-3" id="heading">KYC Documents Upload</h2>
                                            <p>On our mobile app to complete your onboarding process we require your Photo ID and a selfie. We use ID Scan solution which takes less than a minute to complete this for you. </p>
                                            {/*<div class="row justify-content-center">
                                          <div class="col-4">

                                              <button type="button" class="btn btn-primary video-btn2" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/qceO0YPfcVo" data-bs-target="#myModal">
                                                  <i class="fa fa-play"></i> Watch Explainer Video
                                              </button>
                                          </div>
                                      </div>*/}
                                            <div id="msform">
                                                {/* progressbar */}
                                                <ul id="progressbar">
                                                    <NavLink to="/onboarding">
                                                        <li className="active" id="confirm"><strong>1) Scanning Your Id</strong></li></NavLink>
                                                    <NavLink to="/livenesscheck">
                                                        <li id="confirm"><strong>2) Liveness Check</strong></li></NavLink>
                                                    <NavLink to="/finish">
                                                        <li id="confirm"><strong>Finish</strong></li></NavLink>
                                                </ul>
                                                {/* fieldsets */}
                                                <fieldset>
                                                    <div className="form-card">
                                                        <div className="row justify-content-center">
                                                            <h4 className="mb-4">How To Scan Id?</h4>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-12 col-md-4 col-lg-4">
                                                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0'}}>
                                                                    <img className="card-img-top" src="assets/images/photo/scanid1.png" alt="How to scan Id?" />
                                                                    <div className="card-body">
                                                                        <p className="card-text">Try not to take the photo from any other angle. It will work regardless, but parallel is better.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-lg-4">
                                                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0'}}>
                                                                    <img className="card-img-top" src="assets/images/photo/scanid2.png" alt="How to scan Id?" />
                                                                    <div className="card-body">
                                                                        <p className="card-text">Try not to take the photo from any other angle. It will work regardless, but parallel is better.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="hrcss" />
                                                        <div className="row justify-content-center">
                                                            <h4 className="mb-4 mt-2">What to avoid?</h4>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-12 col-md-4 col-lg-4">
                                                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0'}}>
                                                                    <img className="card-img-top" src="assets/images/photo/scanid4.png" alt=" What to avoid?" />
                                                                    <div className="card-body">
                                                                        <p className="card-text">Please avoid flash or glares.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-lg-4">
                                                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0'}}>
                                                                    <img className="card-img-top" src="assets/images/photo/scanid6.png" alt=" What to avoid?" />
                                                                    <div className="card-body">
                                                                        <p className="card-text">Please avoid shadows over the ID.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-lg-4">
                                                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0'}}>
                                                                    <img className="card-img-top" src="assets/images/photo/scanid7.png" alt=" What to avoid?" />
                                                                    <div className="card-body">
                                                                        <p className="card-text">Please remove any background clutter.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-lg-4">
                                                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0'}}>
                                                                    <img className="card-img-top" src="assets/images/photo/scanid3.png" alt=" What to avoid?" />
                                                                    <div className="card-body">
                                                                        <p className="card-text">Please make sure your details aren't blurry.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 col-lg-4">
                                                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0'}}>
                                                                    <img className="card-img-top" src="assets/images/photo/scanid5.png" alt=" What to avoid?" />
                                                                    <div className="card-body">
                                                                        <p className="card-text">Please make sure you are not cropping or skewing the ID.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <NavLink to="/livenesscheck" type="button" className="next action-button">Next</NavLink>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                        {/* Onboarding section end */}
                        <Footer />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Onboarding
