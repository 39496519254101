import { Route, Routes, useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Help from "./Pages/Help/Help";
import General_FAQs from "./Pages/General_FAQs/General_FAQs";
import Onboarding_FAQs from "./Pages/Onboarding_FAQs/Onboarding_FAQs";
import Data_Safety from "./Pages/Data_Safety/Data_Safety";
import Onboarding from "./Pages/Onboarding/Onboarding";
import Livenesscheck from "./Pages/Onboarding/Livenesscheck";
import Finish from "./Pages/Onboarding/Finish";
import Terms_and_Conditions from "./Pages/Terms_and_Conditions/Terms_and_Conditions";
import Complaint_Policy from "./Pages/Complaint_Policy/Complaint_Policy";
import Privacy_Policy from "./Pages/Privacy_Policy/Privacy_Policy";
import Money_Transfer_to_Kenya from "./Pages/Money_Transfer/Money_Transfer_to_Kenya";
import Money_Transfer_to_Uganda from "./Pages/Money_Transfer/Money_Transfer_to_Uganda";
import Money_Transfer_to_Somalia from "./Pages/Money_Transfer/Money_Transfer_to_Somalia";
import Money_Transfer_to_Ethiopia from "./Pages/Money_Transfer/Money_Transfer_to_Ethiopia";
import Money_Transfer_to_UAE from "./Pages/Money_Transfer/Money_Transfer_to_UAE";
import Money_Transfer_to_Turkey from "./Pages/Money_Transfer/Money_Transfer_to_Turkey";
import Money_Transfer_to_Australia from "./Pages/Money_Transfer/Money_Transfer_to_Australia";
import Money_Transfer_to_USA from "./Pages/Money_Transfer/Money_Transfer_to_USA";
import Money_Transfer_to_Djibouti from "./Pages/Money_Transfer/Money_Transfer_to_Djibouti";
import AML_CTF_Policy from "./Pages/Leagal_Pages/AML_CTF_Policy";
import Consumer_Duty_Policy from "./Pages/Leagal_Pages/Consumer_Duty_Policy";
import Error from "./Error";


function App() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App">
  
      <Routes>
      <Route path="/*" element={<Error />} /> 
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About/>} />
      <Route path="/money_transfer_to_kenya" element={<Money_Transfer_to_Kenya/>} />
      <Route path="/money_transfer_to_uganda" element={<Money_Transfer_to_Uganda/>} />
      <Route path="/money_transfer_to_somalia" element={<Money_Transfer_to_Somalia/>} />
      <Route path="/money_transfer_to_ethiopia" element={<Money_Transfer_to_Ethiopia/>} />
      <Route path="/money_transfer_to_uae" element={<Money_Transfer_to_UAE/>} />
      <Route path="/money_transfer_to_turkey" element={<Money_Transfer_to_Turkey/>} />
      <Route path="/money_transfer_to_australia" element={<Money_Transfer_to_Australia/>} />
      <Route path="/money_transfer_to_usa" element={<Money_Transfer_to_USA/>} />
      <Route path="/money_transfer_to_djibouti" element={<Money_Transfer_to_Djibouti/>} />
      <Route path="/general_faq" element={<General_FAQs/>} />
      <Route path="/onboarding_faq" element={<Onboarding_FAQs/>} />
      <Route path="/onboarding" element={<Onboarding/>} />
      <Route path="/livenesscheck" element={<Livenesscheck/>} />
      <Route path="/finish" element={<Finish/>} />
      <Route path="/data_safety" element={<Data_Safety/>} />
      <Route path="/help" element={<Help/>} />
      <Route path="/terms_and_conditions" element={<Terms_and_Conditions/>} />
      <Route path="/complaints-policy" element={<Complaint_Policy/>} />
      <Route path="/privacy-policy" element={<Privacy_Policy/>} />
      <Route path="/aml-ctf-policy" element={<AML_CTF_Policy/>} />
      <Route path="/consumer-duty-policy" element={<Consumer_Duty_Policy/>} />
      <Route path="/contact" element={<Contact/>} />
      </Routes>
      
    </div>
  );
}

export default App;
