import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Preloader from '../../Components/Preloader';
import Contact_Us_Seo from '../../SEO/Contact_Us_Seo';
import ApiConfig from '../../Components/ApiConfig';
import { ToastContainer, toast } from 'react-toastify';

const schema = yup.object().shape({
    firstn: yup.string().required('First name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    subject: yup.string().required('Subject is required'),
    phone: yup.string().required('Phone number is required').matches(/^\+?[0-9]{10,15}$/, 'Phone number is not valid'),
    message: yup.string().required('Message is required'),
    // recaptcha: yup.string().required('Please complete the CAPTCHA'),
});

function Contact() {
    const [isLoading, setIsLoading] = useState(true);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema)
    });
    const [data, setData] = useState({
        firstn: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500);

        const handleScroll = () => {
            const scroll = window.scrollY;
            const stickyHeader = document.getElementById('sticky-header');
            const scrollToTarget = document.querySelector('.scroll-to-target');

            if (scroll < 245) {
                stickyHeader?.classList.remove('sticky-menu');
                scrollToTarget?.classList.remove('open');
            } else {
                stickyHeader?.classList.add('sticky-menu');
                scrollToTarget?.classList.add('open');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onSubmit = async (data) => {
        try {
            const response = await fetch(`${ApiConfig.baseUrl}/contactus/savecontactdetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: data.firstn,
                    email: data.email,
                    phoneno: data.phone,
                    subject: data.subject,
                    comment: data.message,
                    reCaptcha: "Aeifdoasdfisdfeojasiefaaaaaa39",
                })
            });
        
            if (response.ok) {
                toast.success('Form submitted successfully');
                
                // Clear form fields by IDs
                document.getElementById('firstn').value = '';
                document.getElementById('email').value = '';
                document.getElementById('phone').value = '';
                document.getElementById('subject').value = '';
                document.getElementById('message').value = '';

                // setTimeout(() => {
                //     window.location.reload();
                // }, 100);
                
            } else {
                toast.error('Form submission failed');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred');
        }
        
        
    };

    const handleRecaptchaChange = (value) => {
        setValue('recaptcha', value);
    };

    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                <div>
                    <Contact_Us_Seo/>
                    {/* back to top start */}
                    <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <i className="fa-solid fa-angles-up" />
                    </button>
                    <div className="">
                        <Header />
                        <section id="site-breadcumb" style={{ backgroundImage: 'url("assets/images/breadcumb/breadcumb-bd.svg")' }}>
                            <div className="site-breadcumb-wrapper">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="breadcumb-content">
                                                <h1 className="breadcumb-title">Contact Us</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="about-sec contact-page-sec sec-ptb">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="sec-content">
                                            <h2 className="sec-title">Get A Quote</h2>
                                            <form onSubmit={handleSubmit(onSubmit)} className="itco-cform">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="contact-field">
                                                            <input type="text" id="firstn" name="firstn" placeholder="Enter Name" {...register('firstn')} />
                                                            <span className="error">{errors.firstn?.message}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="contact-field">
                                                            <input type="email" id="email" name="email" placeholder="Enter Email" {...register('email')} />
                                                            <span className="error">{errors.email?.message}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="contact-field">
                                                            <input type="text" id="subject" name="subject" placeholder="Enter Subject" {...register('subject')} />
                                                            <span className="error">{errors.subject?.message}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="contact-field">
                                                            <input type="text" id="phone" name="phone" placeholder="Enter Phone" {...register('phone')} />
                                                            <span className="error">{errors.phone?.message}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="contact-field">
                                                            <textarea name="message" id="message" cols={40} rows={4} placeholder="Enter Message..." {...register('message')} />
                                                            <span className="error">{errors.message?.message}</span>
                                                        </div>
                                                        <div className="contact-field">
                                                            <ReCAPTCHA
                                                                sitekey="YOUR_RECAPTCHA_SITE_KEY"
                                                                onChange={handleRecaptchaChange}
                                                            />
                                                            <span className="error">{errors.recaptcha?.message}</span>
                                                        </div>
                                                        <div className="itco-form-btn">
                                                            <button type="submit" className="thm-btn mt-3"><span className="txt">Submit</span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 tab-col-gap">
                                        <div className="sec-content">
                                            <h2 className="sec-title">Get In Touch</h2>
                                            <p>These are the phrases we stay via way of means of in the whole lot we do. Each brand we build, and we create.</p>
                                            <div className="get-in-touch">
                                                <div className="qinfo-item">
                                                    <div className="qinfo-icon"><i className="flaticon-maps-and-flags" /></div>
                                                    <div className="qinfo-box">
                                                        <h5>Address</h5>
                                                        <NavLink to="https://maps.app.goo.gl/RsLMmvmTxNkwSqC37" target='_blank'> 9 Red Lion Court, Alexandra Rd, Hounslow, London TW3 1JS.</NavLink>
                                                    </div>
                                                </div>
                                                <div className="qinfo-item">
                                                    <div className="qinfo-icon"><i className="flaticon-call" /></div>
                                                    <div className="qinfo-box">
                                                        <h5>Phone Number</h5>
                                                        <NavLink to="tel:447766431049">+44 7766 431 049</NavLink>
                                                    </div>
                                                </div>
                                                <div className="qinfo-item mb-0">
                                                    <div className="qinfo-icon"><i className="flaticon-gmail-logo" /></div>
                                                    <div className="qinfo-box">
                                                        <h5>Email ID</h5>
                                                        <NavLink to="mailto:info@pay-quik.com">info@pay-quik.com</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company-social d-flex">
                                                <NavLink to="https://www.facebook.com/" target="_blank"> <i className="fa-brands fa-facebook-f" /></NavLink>
                                                <NavLink to="https://www.linkedin.com/" target="_blank"><i className="fa-brands fa-linkedin-in" /></NavLink>
                                                <NavLink to="https://twitter.com/" target="_blank"><i className="fa-brands fa-twitter" /></NavLink>
                                                <NavLink to="https://www.instagram.com/" target="_blank"><i className="fa-brands fa-instagram" /></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            )}
             <ToastContainer
                position="bottom-right" // Set the position to bottom-right
                autoClose={5000} // Set the auto-close duration (in milliseconds)
                hideProgressBar={false} // Show or hide the progress bar
                newestOnTop={false} // Display newest toast notifications on top
                closeOnClick // Close the toast when clicked
                rtl={false} // Right-to-left support
                pauseOnFocusLoss // Pause toast timer when the window loses focus
                draggable // Make toast notifications draggable
                pauseOnHover
                style={{ fontWeight: '500' }}
            />
        </div>
    )
}

export default Contact;
