import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Preloader from '../../Components/Preloader';
import Data_Safety_Seo from '../../SEO/Data_Safety_Seo';

function Data_Safety() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an asynchronous operation (e.g., fetching data)
        setTimeout(() => {
            setIsLoading(false);
        }, 500); // Simulating a 1-second delay
        // Sticky Menu and Scroll-to-Top
        const handleScroll = () => {
            const scroll = window.scrollY;
            const stickyHeader = document.getElementById('sticky-header');
            const scrollToTarget = document.querySelector('.scroll-to-target');

            if (scroll < 245) {
                stickyHeader?.classList.remove('sticky-menu');
                scrollToTarget?.classList.remove('open');
            } else {
                stickyHeader?.classList.add('sticky-menu');
                scrollToTarget?.classList.add('open');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // thmSwiperInit definition
        const thmSwiperInit = () => {
            console.log('Swiper initialized');
        };

        thmSwiperInit();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                // Your actual content when not loading
                <div>
                    <Data_Safety_Seo/>
                    {/* back to top start */}
                    <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <i className="fa-solid fa-angles-up" />
                    </button>
                    <div className="">
                        <Header />
                        <section id="site-breadcumb" style={{ backgroundImage: 'url("assets/images/breadcumb/breadcumb-bd.svg")' }}>
                            <div className="site-breadcumb-wrapper">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="breadcumb-content">
                                                <h1 className="breadcumb-title">Data Safety</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Data Safety section start */}
                        <section className="service-sec2 sec-ptb">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <div className="sec-content text-center mb-5">
                                            <h2 className="sec-title">Data Safety </h2>
                                            <p>If you have completed your registration with Payquick but haven’t initiated any transaction, then you can send us a request to delete your account with us. Deleting your account will remove your data we have stored with us. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row text-center justify-content-center">
                                    <h4 className="mb-2">Temporary Account Deletion</h4>
                                    <p className="mb-5">
                                        Please follow the below steps to delete your account on a temporary basis.
                                    </p>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                            <img className="card-img-top" src="assets/images/data-safety/1.png" alt="Data Safety" />
                                            <div className="card-body">
                                                <p className="card-text">
                                                    <strong>Step 1:</strong> Go to the dashboard and open
                                                    the ‘Settings’ tab. Click on the ‘Delete My Account’ option.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                            <img className="card-img-top" src="assets/images/data-safety/2.png" alt="Data Safety" />
                                            <div className="card-body">
                                                <p className="card-text mb-4">
                                                    <strong>Step 2:</strong> Click the ‘Yes’ option to
                                                    temporarily delete your account.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                            <img className="card-img-top" src="assets/images/data-safety/3.png" alt="Data Safety" />
                                            <div className="card-body">
                                                <p className="card-text">
                                                    <strong>Step 3:</strong> To proceed with your request, please click on
                                                    the ‘Yes’ option. We will delete all your data
                                                    records stored in our system. You can cancel the request by clicking on
                                                    the ‘No’ option.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                            <img className="card-img-top" src="assets/images/data-safety/4.png" alt="Data Safety" />
                                            <div className="card-body">
                                                <p className="card-text mb-4">
                                                    <strong>Step 4:</strong> Your request for account
                                                    deletion will be submitted. Our team will reach out to you shortly.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className="row text-center justify-content-center">
                                    <h4 className="mb-2 mt-3">Permanent Account Deletion</h4>
                                    <p className="mb-5">
                                        Please follow the below steps to delete your account permanently.
                                    </p>
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                            <img className="card-img-top" src="assets/images/data-safety/1.png" alt="Data Safety?" />
                                            <div className="card-body">
                                                <p className="card-text">
                                                    <strong>Step 1:</strong> Go to the dashboard and open
                                                    the ‘Settings’ tab. Click on the ‘Delete My Account’ option.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                            <img className="card-img-top" src="assets/images/data-safety/2.png" alt="Data Safety?" />
                                            <div className="card-body">
                                                <p className="card-text">
                                                    <strong>Step 2:</strong> Click on the ‘No. Delete my account
                                                    permanently’ option to delete your account
                                                    permanently.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                            <img className="card-img-top" src="assets/images/data-safety/5.png" alt="Data Safety?" />
                                            <div className="card-body">
                                                <p className="card-text">
                                                    <strong>Step 3:</strong> To proceed with your request, please click on
                                                    the ‘Yes’ option. We will delete all your data
                                                    records stored in our system.&nbsp;You can cancel the request by
                                                    clicking on the ‘No’ option.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                            <img className="card-img-top" src="assets/images/data-safety/4.png" alt="Data Safety?" />
                                            <div className="card-body">
                                                <p className="card-text mb-4">
                                                    <strong>Step 4:</strong> Your request for account
                                                    deletion will be submitted. Our team will reach out to you shortly.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </section>

                        {/* Data Safety section end */}


                        <Footer />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Data_Safety
