import React, { useEffect, useState } from 'react';
import Preloader from '../../Components/Preloader';
import ConsumerDutyPolicySeo from '../../SEO/ConsumerDutyPolicySeo';

function Consumer_Duty_Policy() {
    const [isLoading, setIsLoading] = useState(true);
    const [zoom, setZoom] = useState(1);
  
    useEffect(() => {
      // Simulate an asynchronous operation (e.g., fetching data)
      setTimeout(() => {
        setIsLoading(false);
      }, 500); // Simulating a 0.5-second delay
  
      // thmSwiperInit definition
      const thmSwiperInit = () => {
        console.log('Swiper initialized');
      };
  
      thmSwiperInit();
    }, []);
  
    const zoomIn = () => {
      setZoom(prevZoom => Math.min(prevZoom + 0.1, 3)); // Max zoom level of 3
    };
  
    const zoomOut = () => {
      setZoom(prevZoom => Math.max(prevZoom - 0.1, 1)); // Min zoom level of 1
    };
  
    return (
      <div>
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <ConsumerDutyPolicySeo/>
            {/* back to top start */}
            <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <i className="fa-solid fa-angles-up" />
            </button>
            <div className="">
              {/* PDF section start */}
              <section className="" style={{ backgroundColor: '#2A2A2E' }}>
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 text-center pdf-data">
                        <img
                          src='assets/pdf/Consumer_Duty_Policy.png'
                          style={{ transform: `scale(${zoom})`, transition: 'transform 0.3s ease' }}
                          alt="AML CTF Policy"
                        />
                      <div className="zoom-controls">
                        <button onClick={zoomIn}>+</button>
                        <button onClick={zoomOut}>-</button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* PDF section end */}
            </div>
          </div>
        )}
      </div>
    )
}

export default Consumer_Duty_Policy
