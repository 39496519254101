import React from 'react';
import { Helmet } from 'react-helmet';

function AboutSeo() {
  return (
    <div>
        <Helmet>
                <title>About Us | PayQuick</title>
                <meta name="keywords" content="" />
                <meta name="description" content="" />
                <meta property="og:title" content="About Us | PayQuick" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
                <meta property="og:url" content="https://pay-quik.co.uk/about" />
                <meta name="twitter:title" content="About Us | PayQuick" />
                <meta name="twitter:description" content="" />
                <meta name="twitter:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
            </Helmet>
    </div>
  )
}

export default AboutSeo
