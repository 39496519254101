import React from 'react';
import { Helmet } from 'react-helmet';

function Data_Safety_Seo() {
  return (
    <div>
      <Helmet>
        <title>Data Safety | PayQuick</title>
        <meta name="keywords" content="" />
        <meta name="description" content="" />
        <meta property="og:title" content="Data Safety | PayQuick" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
        <meta property="og:url" content="https://pay-quik.co.uk/data_safety" />
        <meta name="twitter:title" content="Data Safety | PayQuick" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
      </Helmet>
    </div>
  )
}

export default Data_Safety_Seo
