import React from 'react';
import { Helmet } from 'react-helmet';

function Complaint_Policy_Seo() {
  return (
    <div>
      <Helmet>
        <title>Complaint Policy | PayQuick</title>
        <meta name="keywords" content="" />
        <meta name="description" content="" />
        <meta property="og:title" content="Complaint Policy | PayQuick" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
        <meta property="og:url" content="https://pay-quik.co.uk/complaints-policy" />
        <meta name="twitter:title" content="Complaint Policy | PayQuick" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
      </Helmet>
    </div>
  )
}

export default Complaint_Policy_Seo
