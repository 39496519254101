import React, { useEffect, useState } from 'react';
import '../../css/steps.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Preloader from '../../Components/Preloader';
import { NavLink } from 'react-router-dom';
import Finish_Seo from '../../SEO/Finish_Seo';

function Finish() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an asynchronous operation (e.g., fetching data)
        setTimeout(() => {
            setIsLoading(false);
        }, 500); // Simulating a 0.5-second delay

        // Sticky Menu and Scroll-to-Top
        const handleScroll = () => {
            const scroll = window.scrollY;
            const stickyHeader = document.getElementById('sticky-header');
            const scrollToTarget = document.querySelector('.scroll-to-target');

            if (scroll < 245) {
                stickyHeader?.classList.remove('sticky-menu');
                scrollToTarget?.classList.remove('open');
            } else {
                stickyHeader?.classList.add('sticky-menu');
                scrollToTarget?.classList.add('open');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // thmSwiperInit definition
        const thmSwiperInit = () => {
            console.log('Swiper initialized');
        };

        thmSwiperInit();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                <div>
                    <Finish_Seo/>
                    {/* back to top start */}
                    <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <i className="fa-solid fa-angles-up" />
                    </button>
                    <div className="">
                        <Header />
                        <section id="site-breadcumb" style={{ backgroundImage: 'url("assets/images/breadcumb/breadcumb-bd.svg")' }}>
                            <div className="site-breadcumb-wrapper">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="breadcumb-content">
                                                <h1 className="breadcumb-title">Onboarding</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Onboarding section start */}
                        <section className="sec-ptb">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                                        <div className="card px-0 pb-0 mb-3">
                                            <h2 className="mb-3" id="heading">KYC Documents Upload</h2>
                                            <p>On our mobile app to complete your onboarding process we require your Photo ID and a selfie. We use ID Scan solution which takes less than a minute to complete this for you. </p>
                                            {/*<div class="row justify-content-center">
                          <div class="col-4">

                              <button type="button" class="btn btn-primary video-btn2" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/qceO0YPfcVo" data-bs-target="#myModal">
                                  <i class="fa fa-play"></i> Watch Explainer Video
                              </button>
                          </div>
                      </div>*/}
                                            <div id="msform">
                                                {/* progressbar */}
                                                <ul id="progressbar">
                                                    <NavLink to="/onboarding">
                                                        <li className="active" id="confirm"><strong>1) Scanning Your Id</strong></li></NavLink>
                                                    <NavLink to="/livenesscheck">
                                                        <li className="active" id="confirm"><strong>2) Liveness Check</strong></li></NavLink>
                                                    <NavLink to="/finish">
                                                        <li className="active" id="confirm"><strong>Finish</strong></li></NavLink>
                                                </ul>
                                                {/* fieldsets */}
                                                <fieldset>
                                                    <div className="form-card">
                                                        <div className="row justify-content-center">
                                                            <div className="col-12 col-md-4 col-lg-4">
                                                                <div className="card cardcss">
                                                                    <img className="card-img-top" src="assets/images/photo/finishimg.png" alt="Finish Image" />
                                                                    <div className="card-body">
                                                                        <p className="card-text">On completion of your IDScan and Liveness check you will get a success screen to confirm completion of onboarding.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-12 text-center">
                                                                <h5 className="purple-text text-center">If you have any further questions then please visit our Onboarding FAQs page.</h5>
                                                            </div>
                                                            <NavLink to="/onboarding_faq" className="next action-button text-center">FAQs</NavLink>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </section>
                        {/* Onboarding section end */}
                        <Footer />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Finish
