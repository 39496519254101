import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Preloader from '../../Components/Preloader';
import AboutSeo from '../../SEO/AboutSeo';

function About() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an asynchronous operation (e.g., fetching data)
        setTimeout(() => {
            setIsLoading(false);
        }, 500); // Simulating a 1-second delay
        // Sticky Menu and Scroll-to-Top
        const handleScroll = () => {
            const scroll = window.scrollY;
            const stickyHeader = document.getElementById('sticky-header');
            const scrollToTarget = document.querySelector('.scroll-to-target');

            if (scroll < 245) {
                stickyHeader?.classList.remove('sticky-menu');
                scrollToTarget?.classList.remove('open');
            } else {
                stickyHeader?.classList.add('sticky-menu');
                scrollToTarget?.classList.add('open');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // thmSwiperInit definition
        const thmSwiperInit = () => {
            console.log('Swiper initialized');
        };

        thmSwiperInit();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                // Your actual content when not loading
                <div>
                   <AboutSeo/> 
                    {/* back to top start */}
                    <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <i className="fa-solid fa-angles-up" />
                    </button>
                    <div className="">
                        <Header />
                        <section id="site-breadcumb" style={{ backgroundImage: 'url("assets/images/breadcumb/breadcumb-bd.svg")' }}>
                            <div className="site-breadcumb-wrapper">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="breadcumb-content">
                                                <h1 className="breadcumb-title">About Us</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* about section start */}
                        <section className="service-sec bg-light-greem sec-ptb">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-5 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="sec-content">
                                            <h6 className="sec-sub-title">About Us</h6>
                                            <h2 className="sec-title">PayQuik</h2>
                                            <p className="sec-text">
                                            PayQuik was started by a group of dedicated entrepreneurs with extensive money transfer experience. After spending over 20 years working at traditional money transfer companies we saw a lot of problems – to put it simply, the majority of money transfer companies have not moved with modern technology and consumer habits. We knew we could do better and so PayQuik was born.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 tab-col-gap">
                                        <div className="service-lists service-lists-style1">
                                            <div className="single-service d-flex align-items-center wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                {/* single-service 1*/}
                                                <div className="single-service-content">
                                                    <h4>Our Vision</h4>
                                                    <p>The goal is to establish a top global digital money transfer business, utilizing advanced technology to enhance the experience for both sender and receiver.</p>
                                                </div>
                                            </div>
                                            <div className="single-service d-flex align-items-center wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                                {/* single-service 2*/}
                                                <div className="single-service-content">
                                                    <h4>Our Mission</h4>
                                                    <p>Make it super easy for people to send money online worldwide.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* about section end */}
                        {/* newslatter section start */}
                        <section className="newslatter-sec bg-green">
                            <div className="container">
                                <div className="row aximo_screenfix_left">
                                    <div className="col-lg-7 p-0">
                                        <div className="video-sec">
                                            <div className="video-sec-overlay d-flex align-items-center justify-content-center">
                                                {/*<NavLink to="#" className="video-btn"><i className="flaticon-play-button-arrowhead" /></NavLink> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 d-flex align-items-center">
                                        <div className="sec-content">
                                            <h2 className="sec-title">Send Money with PayQuick</h2>
                                            <p className="sec-text">Ready to give PayQuik a Try? It’s quick and easy to get started. Sign up today and get your 1st transfer Fee-Free!</p>
                                            <div className="Subscribe-form d-sm-flex">
                                                <div className="submit-btn">
                                                    <NavLink to="https://pay-quik.co.uk/remit/" target='_blank' className="thm-btn">
                                                        <span className="txt">Send Money Now</span>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* newslatter section start */}
                        {/* testimonial section start */}
                        <section className="testimonial-sec sec-ptb">
                            <div className="container">
                                <div className="row">
                                    <div className="offset-lg-3 col-lg-6">
                                        <div className="sec-content text-center mb-5">
                                            <h6 className="sec-sub-title">Clients Testimonials</h6>
                                            <h2 className="sec-title">What Our Customers Say</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <OwlCarousel
                                        className="testimonial-slider owl-carousel owl-theme"
                                        loop={true}
                                        margin={30}
                                        nav={false}
                                        dots={true}
                                        dotsEach={true}
                                        autoplay={true}
                                        autoplayTimeout={3000}
                                        responsive={{
                                            0: { items: 1 }, // 1 item for screen width 0px and up
                                            600: { items: 1 }, // 1 item for screen width 600px and up
                                            1000: { items: 2 } // 2 items for screen width 1000px and up
                                        }}
                                    >
                                        {/* Item 1 */}
                                        <div className="item">
                                            <div className="single-testimonial">
                                                <div className="feedback">
                                                    <div className="rating">
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                    </div>
                                                    <p className="comment">
                                                        “I am one of those people that has ‘an app for everything’. I used to send money back home to my parents using an agent near my workplace but I hated queueing up. I now send money and keep track of it all via the PayQuik app - saves me time and money.”
                                                    </p>
                                                </div>
                                                <div className="customar-info">
                                                    <div className="customar-title">
                                                        <h5>Adamu</h5>
                                                    </div>
                                                </div>
                                                <div className="com-logo">
                                                    <i className="flaticon-ux" />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Item 2 */}
                                        <div className="item">
                                            <div className="single-testimonial">
                                                <div className="feedback">
                                                    <div className="rating">
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                        <i className="flaticon-star" />
                                                    </div>
                                                    <p className="comment">
                                                        “I’ve been supporting my younger brother’s education for years - sending him money to pay for school fees etc. I am a price conscious person so always like to compare services - I always find PayQuik to be reliable and good value for money.”
                                                    </p>
                                                </div>
                                                <div className="customar-info">
                                                    <div className="customar-title">
                                                        <h5>Angela</h5>
                                                    </div>
                                                </div>
                                                <div className="com-logo">
                                                    <i className="flaticon-ux" />
                                                </div>
                                            </div>
                                        </div>

                                    </OwlCarousel>
                                </div>
                            </div>
                        </section>
                        {/* testimonial section end */}

                        {/* app section start */}
                        <Footer />
                    </div>
                </div>
            )}

        </div>

    )
}

export default About
