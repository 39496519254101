import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Preloader from '../../Components/Preloader';
import Help_Seo from '../../SEO/Help_Seo';

function Help() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an asynchronous operation (e.g., fetching data)
        setTimeout(() => {
            setIsLoading(false);
        }, 500); // Simulating a 1-second delay
        // Sticky Menu and Scroll-to-Top
        const handleScroll = () => {
            const scroll = window.scrollY;
            const stickyHeader = document.getElementById('sticky-header');
            const scrollToTarget = document.querySelector('.scroll-to-target');

            if (scroll < 245) {
                stickyHeader?.classList.remove('sticky-menu');
                scrollToTarget?.classList.remove('open');
            } else {
                stickyHeader?.classList.add('sticky-menu');
                scrollToTarget?.classList.add('open');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // thmSwiperInit definition
        const thmSwiperInit = () => {
            console.log('Swiper initialized');
        };

        thmSwiperInit();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                // Your actual content when not loading
                <div>
                    <Help_Seo/>
                    {/* back to top start */}
                    <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <i className="fa-solid fa-angles-up" />
                    </button>
                    <div className="">
                        <Header />
                        <section id="site-breadcumb" style={{ backgroundImage: 'url("assets/images/breadcumb/breadcumb-bd.svg")' }}>
                            <div className="site-breadcumb-wrapper">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="breadcumb-content">
                                                <h1 className="breadcumb-title">Help</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Help section start */}
                        <section className="service-cat-sec" style={{ margin: '60px' }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-3 col-md-3 col-sm-6 wow fadeInUp" data-wow-delay="500ms" data-wow-duration="1500ms">
                                        <div className="single-service">
                                            {/* service item 1 */}
                                            <NavLink to="/onboarding">
                                            <div className="service-content">
                                            <img src="assets/images/photo/kyc.png" alt="KYC Upload"/>
                                                <div className="service-title">KYC Upload</div>
                                            </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-3 col-sm-6 wow fadeInUp" data-wow-delay="700ms" data-wow-duration="1500ms">
                                        <div className="single-service">
                                            {/* service item 2 */}
                                            <NavLink to="/data_safety">
                                            <div className="service-content">
                                            <img src="assets/images/photo//data-safety.png" alt="Data Safety"/>
                                                <div className="service-title">Data Safety</div>
                                            </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-3 col-sm-6 wow fadeInUp" data-wow-delay="700ms" data-wow-duration="1500ms">
                                        <div className="single-service">
                                            {/* service item 2 */}
                                            <NavLink to="/onboarding_faq">
                                            <div className="service-content">
                                            <img src="assets/images/photo/onbordingfaq.png" alt="Onboarding FAQs"/>
                                                <div className="service-title">Onboarding FAQs</div>
                                            </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-3 col-sm-6 wow fadeInUp" data-wow-delay="900ms" data-wow-duration="1500ms">
                                        <div className="single-service">
                                            {/* service item 3 */}
                                            <NavLink to="/general_faq">
                                            <div className="service-content">
                                            <img src="assets/images/photo/faq.png" alt="General FAQs"/>
                                                <div className="service-title">General FAQs</div>
                                            </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-3 col-sm-6 wow fadeInUp" data-wow-delay="1100ms" data-wow-duration="1500ms">
                                        <div className="single-service">
                                            {/* service item 4 */}
                                            <NavLink to="/contact">
                                            <div className="service-content">
                                            <img src="assets/images/photo/contactinfo.png" alt="Contact Info"/>
                                                <div className="service-title">Contact Info</div>
                                            </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Help section end */}


                        <Footer />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Help
