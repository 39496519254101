import React from 'react';
import { Helmet } from 'react-helmet';

function MoneyTransfertoSomaliaSeo() {
  return (
    <div>
      <Helmet>
        <title>Money Transfer to Somalia | PayQuick</title>
        <meta name="keywords" content="" />
        <meta name="description" content="" />
        <meta property="og:title" content="Money Transfer to Somalia | PayQuick" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
        <meta property="og:url" content="https://pay-quik.co.uk/money_transfer_to_somalia" />
        <meta name="twitter:title" content="Money Transfer to Somalia | PayQuick" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="https://pay-quik.co.uk/assets/images/logo/site-logo.png" />
      </Helmet>
    </div>
  )
}

export default MoneyTransfertoSomaliaSeo
