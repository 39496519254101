import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Preloader from '../../Components/Preloader';
import Terms_and_Conditions_Seo from '../../SEO/Terms_and_Conditions_Seo';

function Terms_and_Conditions() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an asynchronous operation (e.g., fetching data)
        setTimeout(() => {
            setIsLoading(false);
        }, 500); // Simulating a 1-second delay
        // Sticky Menu and Scroll-to-Top
        const handleScroll = () => {
            const scroll = window.scrollY;
            const stickyHeader = document.getElementById('sticky-header');
            const scrollToTarget = document.querySelector('.scroll-to-target');

            if (scroll < 245) {
                stickyHeader?.classList.remove('sticky-menu');
                scrollToTarget?.classList.remove('open');
            } else {
                stickyHeader?.classList.add('sticky-menu');
                scrollToTarget?.classList.add('open');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // thmSwiperInit definition
        const thmSwiperInit = () => {
            console.log('Swiper initialized');
        };

        thmSwiperInit();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                // Your actual content when not loading
                <div>
                    <Terms_and_Conditions_Seo/>
                    {/* back to top start */}
                    <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <i className="fa-solid fa-angles-up" />
                    </button>
                    <div className="">
                        <Header />
                        <section id="site-breadcumb" style={{ backgroundImage: 'url("assets/images/breadcumb/breadcumb-bd.svg")' }}>
                            <div className="site-breadcumb-wrapper">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="breadcumb-content">
                                                <h1 className="breadcumb-title">Terms and Conditions</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* Terms and Conditions section start */}
                        <section className="service-details sec-ptb">
                            <div className="container">
                                <div className="row flex-column-reverse flex-xl-row">
                                    <div className="col-xl-12">
                                        <div className="service-details-wrapper">
                                            <div className="service-details-content">
                                                <div className="info-part">
                                                    <h4>1. Parties</h4>
                                                    <p className="mb-4">
                                                        This Agreement is between you and Pay-Quik Financial Services LTD – Company
                                                        number 11809790. Small Payment Institution (SPI) 9 Red Lion Court, Alexandra Rd,
                                                        Hounslow, London TW3 1JS. United Kingdom.
                                                    </p>
                                                </div>
                                                <div className="info-part">
                                                    <h4>2. Service</h4>
                                                    <p className="mb-4">
                                                        Pay-Quik is registered with FCA for the for the provision of remittance. Pay-Quik is
                                                        only a Remittance Service Provider; Our main business is money transfer services.
                                                        For security reasons, we recommend that you only send money through the Service
                                                        to people you know personally. If you choose to pay third parties for goods and
                                                        services using the Service, you acknowledge that Pay-Quik has no control over, and
                                                        is not responsible for, the quality, safety, legality, or delivery of such goods or
                                                        services and that any such use of the Service is entirely at your own risk.
                                                    </p>
                                                </div>
                                                <div className="info-part">
                                                    <h4>3. Consents</h4>
                                                    <p className="mb-4">
                                                        You agree that by placing the transfer funds into the Pay-Quik Account you
                                                        irrevocably consent to the execution of the Transfer transaction.
                                                        By providing us with your personal information, such as address, telephone
                                                        number, mobile number and/or email address, you consent to receiving notices
                                                        and other communications from Pay-Quik via post, telephone, email and/or SMS
                                                        message, subject to our Privacy Policy. You acknowledge that message and data
                                                        rates may apply to SMS messages. Pay-Quik may send notices to you about your
                                                        Transfer, your use of the Service or for other purposes, such as providing you with
                                                        details regarding new developments, products, services, and special offers. We may
                                                        use any email address or other personal information you provide to us at any time
                                                        for this purpose.
                                                    </p>
                                                    <p className='mb-4'>
                                                        However, if you prefer not to be contacted for any of the above-mentioned reasons
                                                        or via any of the above-mentioned means, you have the option to unsubscribe or
                                                        opt-out if you so desire. To stop receiving SMS messages, reply “STOP” to any Pay-
                                                        Quik SMS message received. To stop receiving email messages, please send an
                                                        email message to unsubscribe@pay-quik.com with your request. You may also
                                                        e-mail info@pay-quik.com for assistance.
                                                    </p>
                                                </div>
                                                <div className="info-part">
                                                    <h4>4. Transfer Information</h4>
                                                    <ul className="info-number-list">
                                                        <li>
                                                            <span>4.1</span>
                                                            The Pay-Quik service is provided by Pay-Quik Financial Services LTD (“Pay-
                                                            Quik”, “we”, “us” or “our”) through our online platform. Reference to our Money
                                                            Remittance process in this terms and conditions include the use of our website
                                                            money transfer.
                                                        </li>
                                                        <li>
                                                            <span>4.2</span>
                                                            This Agreement and Pay-Quik service allows you to send online money transfer
                                                            (a “Transfer”) to the person named on the form (the “Recipient”) and allows them to
                                                            receive the money into their mobile phone wallet.
                                                        </li>
                                                        <li>
                                                            <span>4.3</span>
                                                            In order to use the Pay-Quik remittance facility for the first time, you must set
                                                            up your “Profile” by entering your personal information, a “User ID” (which your
                                                            email address is used for identification purposes and to login to your Profile), a
                                                            password, a valid Identification and a contact number. For any consecutive
                                                            Transfers you can use your existing Profile using your User ID and password to
                                                            login. All information provided by you shall be stored in your Profile, and shall be
                                                            maintained by us or our “Service Providers” (any company or companies engaged
                                                            by us or our contractors to provide our Remittance Services) based upon the
                                                            information supplied by you.
                                                        </li>
                                                        <li>
                                                            <span>4.4</span>
                                                            You cannot send a Transfer on behalf of a third party.
                                                        </li>
                                                        <li>
                                                            <span>4.5</span>
                                                            For online transfers, you can either send the money within the same country or
                                                            to a different “Receive Country”. Once we have accepted your Transfer and the
                                                            money has been collected from your bank account, the Recipient can collect the
                                                            money in the agreed currency and agreed exchange rates in the mobile phone
                                                            number that you stated in the online form. We will not contact the Recipient to
                                                            advise them when the money has been deposited. However, you or the receiver
                                                            may receive SMS alert to confirm that the transaction has been completed.
                                                        </li>
                                                        <li>
                                                            <span>4.5</span>
                                                            We do not offer our services in all countries. You can call us or visit our website
                                                            to find out the availability of our services and addresses and opening times of
                                                            locations offering the services.
                                                        </li>
                                                        <li>
                                                            <span>4.6</span>
                                                            We do not offer our services in all countries. You can call us or visit our website
                                                            to find out the availability of our services and addresses and opening times of
                                                            locations offering the services.
                                                        </li>
                                                        <li>
                                                            <span>4.7</span>
                                                            We may communicate with you electronically (for example, by email) unless we
                                                            are required by law or regulatory requirements to communicate in another way. If
                                                            you do not wish to receive communications electronically, you may not use Pay-
                                                            Quik Remittance Services.
                                                        </li>
                                                        <li>
                                                            <span>4.8</span>
                                                            You agree to promptly update your records if your email address or other
                                                            contact information in your Profile changes. Follow the instructions on our website
                                                            to update your Profile.
                                                        </li>

                                                    </ul>
                                                </div>
                                                <div className="info-part">
                                                    <h4>5. Foreign Exchange</h4>
                                                    <ul className="info-number-list">
                                                        <li>
                                                            <span>5.1</span>
                                                            A transfer will be paid out in the currency of the beneficiary’s country.
                                                        </li>
                                                        <li>
                                                            <span>5.2</span>
                                                            In addition to the transfer fee applicable to the transaction, in cases where the
                                                            beneficiary will receive a currency other than UK dollars, Pay-Quik will convert AUD
                                                            into the payout currency at Pay-Quik’s retail currency exchange rate at the time of
                                                            the transfer.
                                                        </li>
                                                        <li>
                                                            <span>5.3</span>
                                                            Any monies arising from the difference (if any) between Pay-Quik’s exchange
                                                            rate and the wholesale exchange rate received by Pay-Quik will be kept by Pay-
                                                            Quik.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="info-part">
                                                    <h4>6. Refund Information</h4>
                                                    <ul className="info-number-list">
                                                        <li>
                                                            <span>6.1</span>
                                                            Unless payment to the beneficiary has already been made, and to the extent
                                                            permitted by law, if you request a refund of your transfer (or your transfer is
                                                            cancelled for any reason), Pay-Quik will refund you the principal transfer amount (at
                                                            Pay-Quik’s exchange rate at the time of the transfer) on the condition that you
                                                            provide Pay-Quik with a copy of a valid receipt and present valid identification. A
                                                            receipt is not valid unless the corresponding transaction is processed through Pay-
                                                            Quik’s computerized system and contains the computer-generated imprint of the
                                                            representative’s name and address and the correct order number.
                                                        </li>
                                                        <li>
                                                            <span>6.2</span>
                                                            To the extent permitted by law, Pay-Quik will not refund the transfer fee if the
                                                            Transfer is stopped or otherwise cancelled at your request.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="info-part">
                                                    <h4>7. Anti-Money Laundering and Counter-Terrorism
                                                        Financing</h4>
                                                    <ul className="info-number-list">
                                                        <li>
                                                            <span>7.1</span>
                                                            You must provide all information reasonably required by Pay-Quik to comply
                                                            with the Anti-Money Laundering and any Counter-terrorism Financing Act or to
                                                            comply with any laws or regulations in UK or any other country.
                                                        </li>
                                                        <li>
                                                            <span>7.2</span>
                                                            Pay-Quik may delay, block, or refuse to process any transfer without incurring
                                                            any liability or without informing you of the reasons if Pay-Quik suspects that you
                                                            have breached any laws or regulations in UK or in any other country.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="info-part">
                                                    <h4>8. Receipt</h4>
                                                    <p className='mb-4'>
                                                        Pay-Quik will make available a receipt which can, at your election, either be emailed
                                                        to your nominated email address or collected from the Pay-Quik office.
                                                        The receipt will set out:
                                                    </p>
                                                    <ul className="info-list">
                                                        <li>
                                                            a unique identifier number (to identify the transfer);
                                                        </li>
                                                        <li>
                                                            information relating to the beneficiary;
                                                        </li>
                                                        <li>
                                                            the amount of the transfer;
                                                        </li>
                                                        <li>
                                                            the transfer fee for the service;
                                                        </li>
                                                        <li>
                                                            Pay-Quik’s exchange rate (where applicable); and
                                                        </li>
                                                        <li>
                                                            The date of the transaction.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="info-part">
                                                    <h4>9. Liability and Indemnity</h4>
                                                    <ul className="info-number-list">
                                                        <li>
                                                            <span>9.1</span>
                                                            Nothing in this Agreement operates to exclude, restrict or modify, or has the
                                                            effect of excluding, restricting or modifying, any statutory condition, warranty,
                                                            guarantee, right or remedy implied by law (including the UK Consumer Law) and
                                                            which cannot be excluded, restricted or modified.
                                                        </li>
                                                        <li>
                                                            <span>9.2</span>
                                                            If Pay-Quik fails to comply with a statutory guarantee which by law may not be
                                                            excluded, then to the extent the law permits Pay-Quik to limit its liability in respect
                                                            of such failure:
                                                            <ul className='info-number-list'>
                                                                <li>
                                                                    <span>9.2.1</span>
                                                                    the re-transfer of the principal transfer amount; or
                                                                </li>
                                                                <li>
                                                                    <span>9.2.2</span>
                                                                    the payment of the cost of re-transferring the principal transfer amount.
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <span>9.3</span>
                                                            The liability of Pay-Quik excludes, to the extent that any loss or damage
                                                            suffered by you is attributable to fault or negligence on your part or on the part of
                                                            any person for whom you are responsible, such loss or damage. If you provide Pay-
                                                            Quik with incorrect information in the form regarding the beneficiary, Pay-Quik will
                                                            not be liable for the non-execution or the defective execution of the transfer.
                                                            However, Pay-Quik will make all reasonable efforts to recover the transfer. Pay-Quik
                                                            may charge you for the recovery of the transfer.
                                                        </li>
                                                        <li>
                                                            <span>9.4</span>
                                                            Except as provided below, Pay-Quik shall not be liable for any amount in excess
                                                            of the amount equal to the principal transfer amount and the transfer fee paid by
                                                            you. Pay-Quik accepts no liability for service delays or any failure to perform the
                                                            transaction in accordance with your instructions if these are due to local
                                                            regulations or circumstances outside Pay-Quik’s control. Pay-Quik will not be liable
                                                            for any indirect or consequential loss. Pay-Quik will not be responsible for goods
                                                            and services that may be paid for by a transfer.
                                                        </li>
                                                        <li>
                                                            <span>9.5</span>
                                                            Nothing in this Clause 9 will limit Pay-Quik’s liability for:
                                                            <ul className='info-list'>
                                                                <li>
                                                                    death or personal injury from its negligence; or
                                                                </li>
                                                                <li>
                                                                    fraud committed by Pay-Quik.
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <span>9.6</span>
                                                            You agree to indemnify and hold Pay-Quik harmless from and against any loss,
                                                            damage, cost, claim or liability (including direct and consequential losses such as
                                                            loss of profits) incurred by Pay-Quik:
                                                            <ul className='info-number-list'>
                                                                <li>
                                                                    <span>9.6.1</span>
                                                                    arising from or as a result of your breach of this agreement;
                                                                </li>
                                                                <li>
                                                                    <span>9.6.2</span>
                                                                    arising from or as a result of any misdescription in the transfer form;
                                                                </li>
                                                                <li>
                                                                    <span>9.6.3</span>
                                                                    arising from or as a result of payment to a person whom Pay-Quik reasonably
                                                                    believes to be the beneficiary;
                                                                </li>
                                                                <li>
                                                                    <span>9.6.4</span>
                                                                    in relation to the person you have nominated as the beneficiary;
                                                                </li>
                                                                <li>
                                                                    <span>9.6.5</span>
                                                                    arising from or as a result of Pay-Quik’s cancellation of a transfer pursuant to
                                                                    your instructions; or
                                                                </li>
                                                                <li>
                                                                    <span>9.6.6</span>
                                                                    arising from or as a result of Pay-Quik’s cancellation of a Transfer in
                                                                    accordance with Clause 7.2.
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <span>9.7</span>
                                                            You agree to indemnify, defend and hold Pay-Quik harmless, its officers,
                                                            directors, employees, from and against all losses, expenses, damages, and costs,
                                                            including reasonable lawyer fees, resulting from any violation of this agreement by
                                                            you.
                                                        </li>
                                                        <li>
                                                            <span>9.8</span>
                                                            If you provide incorrect information about the beneficiary and the funds are
                                                            deposited to an account which is not the beneficiary’s Account, Pay-Quik shall not
                                                            be liable for any resulting loss.
                                                        </li>
                                                        <li>
                                                            <span>9.9</span>
                                                            Once the funds are transferred to the Beneficiary, the transfer of the funds is
                                                            final and irrevocable.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="info-part">
                                                    <h4>10. Privacy Compliance, Identity Verification and
                                                        Consents</h4>
                                                    <p className="mb-4">
                                                        Pay-Quik will use the information provided by you, including personal information
                                                        about yourself and the beneficiary, to provide you with the Service and to manage
                                                        its ongoing relationship with you. By signing the receipt for your transfer, you, the
                                                        sender, confirm that all details you are providing to Pay-Quik are correct. In
                                                        providing your personal information to Pay-Quik, you consent for the same to be
                                                        used in accordance with Pay-Quik’s Privacy Policy.
                                                    </p>
                                                </div>
                                                <div className="info-part">
                                                    <h4>11. Complaints</h4>
                                                    <ul className="info-number-list">
                                                        <li>
                                                            <span>11.1</span>
                                                            If you need to make a complaint relating to the service, please contact Pay-
                                                            Quik’s Customer Services by telephone or email.
                                                        </li>
                                                        <li>
                                                            <span>11.2</span>
                                                            Please see Pay-Quik’s Privacy Policy for guidance on making a complaint
                                                            related to Pay-Quik’s treatment or handling of your personal information.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="info-part">
                                                    <h4>12. Entire Agreement</h4>
                                                    <p className="mb-4">
                                                        You agree that our agreement includes only these terms and conditions and the
                                                        transfer form and this is the entire agreement and understanding between you and
                                                        Pay-Quik and supersedes and terminates any prior agreement between you and
                                                        Pay-Quik.
                                                    </p>
                                                </div>
                                                <div className="info-part">
                                                    <h4>13. Rights of Third Parties</h4>
                                                    <p className="mb-4">
                                                        A person who is not a party to the agreement will not have any rights to enforce
                                                        any provision of the agreement other than at law.
                                                    </p>
                                                </div>
                                                <div className="info-part">
                                                    <h4>14. Severability</h4>
                                                    <p className="mb-4">
                                                        In the event any provision (or part provision) of the agreement is held by any court
                                                        or authority of competent jurisdiction to be invalid, illegal, or unenforceable, that
                                                        provision or part-provision will, to the extent required, be deemed not to form part
                                                        of the agreement and the validity and enforceability of the other provisions of the
                                                        agreement will not be affected.
                                                    </p>
                                                </div>
                                                <div className="info-part">
                                                    <h4>15. Language, Governing Law, and Jurisdiction</h4>
                                                    <ul className="info-number-list">
                                                        <li>
                                                            <span>15.1</span>
                                                            Where Pay-Quik at its discretion provides you with a translation of the English
                                                            version of any part of the agreement, you agree that the translation is only for your
                                                            convenience and does not form part of the agreement. The English version of the
                                                            agreement will govern the provision of the service by Pay-Quik to you.
                                                        </li>
                                                        <li>
                                                            <span>15.2</span>
                                                            The agreement is to be construed in accordance with the law of UK and any
                                                            dispute with respect to the agreement is to be subject to the exclusive jurisdiction
                                                            of the UK courts.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="info-part">
                                                    <h4>16. Contact Us</h4>
                                                    <ul className="info-number-list">
                                                        <li>
                                                            <span>16.1</span>
                                                            Pay-Quik may be contacted via email at <NavLink to="mailto:info@pay-quick.com">info@pay-quick.com</NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="info-part">
                                                    <h4>17. Notices</h4>
                                                    <ul className="info-number-list">
                                                        <li>
                                                            <span>17.1</span>
                                                            any notice given or made under this agreement may be sent by email if:
                                                            <ul className='info-number-list'>
                                                                <li>
                                                                    <span>17.1.1</span>
                                                                    the notice is sent to the email address last notified by the intended recipient
                                                                    to the sender; and
                                                                </li>
                                                                <li>
                                                                    <span>17.1.2</span>
                                                                    the sender keeps an electronic or printed copy of the notice sent;
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <span>17.2</span>
                                                            a notice sent by email will be deemed to have been given on the first to occur
                                                            of:
                                                            <ul className='info-number-list'>
                                                                <li>
                                                                    <span>17.2.1</span>
                                                                    receipt by the sender of an email acknowledgement from the recipient’s
                                                                    information system showing that the notice has been delivered to the email
                                                                    address stated above;
                                                                </li>
                                                                <li>
                                                                    <span>17.2.2</span>
                                                                    the time that the notice enters an information system which is under the
                                                                    control of the recipient; or
                                                                </li>
                                                                <li>
                                                                    <span>17.2.3</span>
                                                                    the time that the notice is first opened or read by an employee or officer of
                                                                    the recipient.
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="info-part">
                                                    <h4>18. Termination</h4>
                                                    <ul className="info-number-list">
                                                        <li>
                                                            <span>18.1</span>
                                                            This agreement may be terminated immediately by you or Pay-Quik by notice
                                                            to the other. However, termination by either party shall not affect any transfer or
                                                            other transaction previously entered and shall not relieve either party of any
                                                            outstanding obligations arising out of this agreement, nor shall it relieve you of any
                                                            obligations arising out of any transfer entered prior to such termination.
                                                        </li>
                                                        <li>
                                                            <span>18.2</span>
                                                            In the event that Pay-Quik is made aware of or has reason to believe any of the
                                                            following:
                                                            <ul className='info-number-list'>
                                                                <li>
                                                                    <span>18.2.1</span>
                                                                    that you have provided false or misleading information to Pay-Quik; or
                                                                </li>
                                                                <li>
                                                                    <span>18.2.2</span>
                                                                    that you have participated or are participating or have assisted or are
                                                                    assisting in money laundering or terrorist financing; or
                                                                </li>
                                                                <li>
                                                                    <span>18.2.3</span>
                                                                    that you are being officially investigated by law enforcement and/or
                                                                    regulatory agencies; or
                                                                </li>
                                                                <li>
                                                                    <span>18.2.4</span>
                                                                    that you have contravened any laws, then Pay-Quik, at its sole discretion,
                                                                    may terminate this agreement immediately by notice to you, to the extent notice is
                                                                    permitted under applicable laws, and Pay-Quik shall be relieved of any obligations
                                                                    set out in this agreement or arising out of the transactions contemplated by the
                                                                    agreement between the parties.
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="info-part">
                                                    <h4>19. Amendment</h4>
                                                    <ul className="info-number-list">
                                                        <li>
                                                            <span>19.1</span>
                                                            Pay-Quik may make modifications, alterations, changes, or variations to any
                                                            term of this agreement, by notifying you in writing, including by email, of the details
                                                            of the amendment or modification. Any modification or change to this agreement
                                                            will take effect after a period of 14 days has elapsed from the date when you are
                                                            notified of the modification or change. You may not make any changes or
                                                            modifications to this agreement.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Terms and Conditions section end */}

                        <Footer />
                    </div>
                </div>
            )}

        </div>
    )
}

export default Terms_and_Conditions
