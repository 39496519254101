import React from 'react'
import { NavLink } from 'react-router-dom'

function Footer() {
  return (
    <div>
       {/* footer start */}
       <footer className="footer-sec">
              <div className="section-overlay sec-pt">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6 col-xl-3">
                      <div className="footer-widget">
                        <div className="footer-widget-logo">
                          <NavLink to="/"><img src="assets/images/logo/logo1.png" alt="site-logo" /></NavLink>
                        </div>
                        <div className="footer-widget-content">
                          <p className="ft-text">
                          Pay-Quik Financial Services Limited, registered at Companies House with registration number 11809790, is regulated by the FCA under Firm Reference Number 834653.
                          </p>
                          <div className="footer-social">
                            <div className="footer-social-icon d-flex">
                              <NavLink to="https://www.facebook.com/" target="_blank"> <i className="fa-brands fa-facebook-f" /></NavLink>
                              <NavLink to="https://www.linkedin.com/" target="_blank"><i className="fa-brands fa-linkedin-in" /></NavLink>
                              <NavLink to="https://twitter.com/" target="_blank"><i className="fa-brands fa-twitter" /></NavLink>
                              <NavLink to="https://www.instagram.com/" target="_blank"><i className="fa-brands fa-instagram" /></NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                      <div className="footer-widget">
                        <h4 className="footer-widget-title tx-white">Quick Links</h4>
                        <div className="footer-widget-content">
                          <div className="footer-links">
                            <ul>
                            <li className="">
                          <NavLink to="/about">About Us</NavLink>
                        </li>
                        <li className="">
                          <NavLink to="/help">Help</NavLink>
                        </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                      <div className="footer-widget pages-links">
                        <h4 className="footer-widget-title tx-white">Leagal Links</h4>
                        <div className="footer-widget-content">
                          <div className="footer-links">
                            <ul>
                              <li><NavLink to="/terms_and_conditions">Terms and Conditions</NavLink></li>
                              <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                              <li><NavLink to="/complaints-policy">Complaint Policy</NavLink></li>
                              <li><NavLink to="/aml-ctf-policy" target='_blank'>AML-CTF Policy</NavLink></li>
                              <li><NavLink to="/consumer-duty-policy" target='_blank'>Consumer Duty Policy</NavLink></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                      <div className="footer-widget">
                        <h4 className="footer-widget-title tx-white">Contact Details</h4>
                        <div className="footer-widget-content">
                          <div className="footer-recent-post">
                            <ul>
                              <li className="blog-sidebar-box-item blog-item d-flex">
                                <div className="content-box">
                                  <div className="meta-box">
                                    <ul className="meta-info">
                                      <li>
                                        <div className="icon">
                                          <i className="flaticon-maps-and-flags" />
                                        </div>
                                        <span>
                                        <NavLink to="https://maps.app.goo.gl/RsLMmvmTxNkwSqC37" target='_blank'> 9 Red Lion Court, Alexandra Rd, Hounslow, London TW3 1JS.</NavLink>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                              <li className="blog-sidebar-box-item blog-item d-flex">
                                {/* blog sidebar box item 2 */}
                                <div className="content-box">
                                  <div className="meta-box">
                                    <ul className="meta-info">
                                      <li>
                                        <div className="icon">
                                          <i className="flaticon-call" />
                                        </div>
                                        <span><NavLink to="tel:447766431049">+44 7766 431 049</NavLink></span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                              <li className="blog-sidebar-box-item blog-item d-flex">
                                {/* blog sidebar box item 2 */}
                                <div className="content-box">
                                  <div className="meta-box">
                                    <ul className="meta-info">
                                      <li>
                                        <div className="icon">
                                          <i className="flaticon-gmail-logo" />
                                        </div>
                                        <span><NavLink to="mailto:info@pay-quik.com">info@pay-quik.com</NavLink></span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sx-12 p-0">
                      <hr className="footer-hr" />
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row footer-copyright">
                    <div className="col">
                      <div className="footer-copyright-text text-center">
                        <p className="mb-0">
                        Copyright &copy;
                                <script type="text/javascript ">
                                    document.write(new Date().getFullYear());
                                </script>
                                <NavLink to="/" class="m-0">PayQuick </NavLink> All Rights Reserved.
                                | Powered By <NavLink to="https://www.calyx-solutions.com/ " class="author_link " target="_blank ">
                                    Calyx Solutions.
                                </NavLink>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
    </div>
  )
}

export default Footer
