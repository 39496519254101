import React, { useEffect, useState } from 'react';
import Preloader from '../../Components/Preloader';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { NavLink } from 'react-router-dom';
import Calculator from '../../Components/Calculator';
import MoneyTransfertoKenyaSeo from '../../SEO/MoneyTransfertoKenyaSeo';

function Money_Transfer_to_Kenya() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      // Simulate an asynchronous operation (e.g., fetching data)
      setTimeout(() => {
        setIsLoading(false);
      }, 500); // Simulating a 1-second delay
      // Sticky Menu and Scroll-to-Top
      const handleScroll = () => {
        const scroll = window.scrollY;
        const stickyHeader = document.getElementById('sticky-header');
        const scrollToTarget = document.querySelector('.scroll-to-target');
  
        if (scroll < 245) {
          stickyHeader?.classList.remove('sticky-menu');
          scrollToTarget?.classList.remove('open');
        } else {
          stickyHeader?.classList.add('sticky-menu');
          scrollToTarget?.classList.add('open');
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      // thmSwiperInit definition
      const thmSwiperInit = () => {
        console.log('Swiper initialized');
      };
  
      thmSwiperInit();
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
  
    return (
      <div>
        {isLoading ? (
          <Preloader />
        ) : (
          // Your actual content when not loading
          <div>
            <MoneyTransfertoKenyaSeo/>
            {/* back to top start */}
            <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <i className="fa-solid fa-angles-up" />
            </button>
            <div className="fix">
              <Header />
              {/* hero section start */}
              <section className="hero-sec bg-green hero-ptb">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="hero-content">                        
                      <img src='assets/images/flag_with_map/Kenya.svg' alt='Money Transfer to Kenya'/><br/>
                        <span className="hero-tagline text-teal">Send Money to</span>
                        <h1 className="hero-title text-white">Kenya</h1>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="hero-images hero-style1">
                        <div className="hero-feature-img feature-one br-style1">
                         <Calculator/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* hero section end */}
  
              {/* about section start */}
              <section className="about-sec sec-ptb">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="sec-images">
                        <div className="sec-img-one overflow-hidden wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1500ms">
                          <img src="assets/images/about/about01.png" alt="about-img" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 tab-col-gap wow fadeInUp" data-wow-delay="500ms" data-wow-duration="1500ms">
                      <div className="sec-content">
                        <h6 className="sec-sub-title">Fast & Easy way to send money</h6>
                        <h2 className="sec-title">Trustworthy Money Transfer service</h2>
                        <p className="sec-text">
                        Our services offer a dependable money transfer solution to all of our clients. It offers a safe and secure way to transmit money overseas. The procedure is straightforward and easy to understand. When sending money anywhere in the world, you can rely on our money transfer service to ensure that it is delivered to the intended destination.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* about section end */}
              {/* features section start */}
              <section className="service-sec bg-light-greem sec-ptb">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                      <div className="sec-content">
                        <h6 className="sec-sub-title">Our features</h6>
                        <h2 className="sec-title">Why choose PayQuik</h2>
                        <p className="sec-text">
                          PayQuik offers a reliable, digital money transfer service meeting the needs of our customers.
                        </p>
  
                      </div>
                    </div>
                    <div className="col-lg-12 tab-col-gap">
                      <div className="service-lists service-lists-style1">
                        <div className="single-service align-items-center wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                          {/* single-service 1*/}
                          <div className="single-service-icon">
                          <img src="assets/img/icons/quick-transfer.svg"/>
                          </div>
                          <div className="single-service-content">
                            <h4>Global Payments</h4>
                            <p>We offer users worldwide access to a large and practical partner network for deposits and withdrawals.</p>
                          </div>
                        </div>
                        <div className="single-service align-items-center wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                          {/* single-service 2*/}
                          <div className="single-service-icon">
                          <img src="assets/img/icons/low-cost.svg"/>
                          </div>
                          <div className="single-service-content">
                            <h4>24/7 Service</h4>
                            <p>Through our website, customer support representatives, and mobile app, we provide an online money transfer service.</p>
                          </div>
                        </div>
                        <div className="single-service align-items-center wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
                          {/* single-service 3*/}
                          <div className="single-service-icon">
                          <img src="assets/img/icons/hassel-free.svg"/>
                          </div>
                          <div className="single-service-content">
                            <h4>Competitive Rates</h4>
                            <p>Compare our prices to those of the competitors. We offer very transparent and competitive pricing. When you work with us, you save more. </p>
                          </div>
                        </div>
                        <div className="single-service align-items-center wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                          {/* single-service 4*/}
                          <div className="single-service-icon">
                          <img src="assets/img/icons/safe-secure.svg"/>
                          </div>
                          <div className="single-service-content">
                            <h4>Safe and Secure</h4>
                            <p>Advanced privacy and data encryption technology.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* features section end */}
              {/* newslatter section start */}
              <section className="newslatter-sec bg-green">
                <div className="container">
                  <div className="row aximo_screenfix_left">
                    <div className="col-lg-7 p-0">
                      <div className="video-sec">
                        <div className="video-sec-overlay d-flex align-items-center justify-content-center">
                          {/*<NavLink to="#" className="video-btn"><i className="flaticon-play-button-arrowhead" /></NavLink> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 d-flex align-items-center">
                      <div className="sec-content">
                        <h2 className="sec-title">Send Money with PayQuick</h2>
                        <p className="sec-text">Ready to give PayQuik a Try? It’s quick and easy to get started. Sign up today and get your 1st transfer Fee-Free!</p>
                        <div className="Subscribe-form d-sm-flex">
                          <div className="submit-btn">
                            <NavLink to="https://pay-quik.co.uk/remit/" target='_blank' className="thm-btn">
                              <span className="txt">Send Money Now</span>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* newslatter section start */}
  
              {/* app section start */}
              <section className="about-sec about-sec2 sec-ptb bg-light-greem">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="sec-images sec-img-style">
                        <div className="sec-img-one wow fadeInLeft" data-wow-delay="0.5s" data-wow-duration="1500ms">
                          <img src="assets/images/about/about04.png" alt="about-img" />
                        </div>
                        <div className="sec-img-info2 d-flex flex-column justify-content-center align-items-center wow fadeInRight" data-wow-delay="0.5s" data-wow-duration="1500ms">
                          <div className="">
                            <NavLink to="/" target='_blank'>
                              <img className="mb-3" src="assets/images/google-app.png" alt="about-img" />
                            </NavLink>
                            <NavLink to="/" target='_blank'>
                              <img src="assets/images/ios-app.png" alt="about-img" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 tab-col-gap wow fadeInUp" data-wow-delay="500ms" data-wow-duration="1500ms">
                      <div className="sec-content">
                        <h6 className="sec-sub-title">Download our app</h6>
                        <h2 className="sec-title">Save time with the PayQuick app</h2>
                        <p className="sec-text">
                          Begin your money transfer journey with PayQuick app. Download our app from Play Store or App Store and enjoy benefits that comes along:
                        </p>
                        <ul className="sec-list-item">
                          <li><i className="fa-solid fa-square-check" /> Easy sign up.</li>
                          <li><i className="fa-solid fa-square-check" /> Save time with Repeat Transfer.</li>
                          <li><i className="fa-solid fa-square-check" /> Track your payments in real time.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
  
              {/* app section start */}
              <Footer />
            </div>
          </div>
        )}
  
      </div>
  
    )
}

export default Money_Transfer_to_Kenya
