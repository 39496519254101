import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Preloader from '../../Components/Preloader';
import General_FAQs_Seo from '../../SEO/General_FAQ_Seo';


function General_FAQs() {
    const [isLoading, setIsLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(null); // State to keep track of the active accordion

    useEffect(() => {
        // Simulate an asynchronous operation (e.g., fetching data)
        setTimeout(() => {
            setIsLoading(false);
        }, 500); // Simulating a 0.5-second delay

        // Sticky Menu and Scroll-to-Top
        const handleScroll = () => {
            const scroll = window.scrollY;
            const stickyHeader = document.getElementById('sticky-header');
            const scrollToTarget = document.querySelector('.scroll-to-target');

            if (scroll < 245) {
                stickyHeader?.classList.remove('sticky-menu');
                scrollToTarget?.classList.remove('open');
            } else {
                stickyHeader?.classList.add('sticky-menu');
                scrollToTarget?.classList.add('open');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // thmSwiperInit definition
        const thmSwiperInit = () => {
            console.log('Swiper initialized');
        };

        thmSwiperInit();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleAccordionClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                <div>
                    <General_FAQs_Seo/>
                    {/* back to top start */}
                    <button className="scroll-top scroll-to-target" data-target="html" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <i className="fa-solid fa-angles-up" />
                    </button>
                    <div className="">
                        <Header />
                        <section id="site-breadcumb" style={{ backgroundImage: 'url("assets/images/breadcumb/breadcumb-bd.svg")' }}>
                            <div className="site-breadcumb-wrapper">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="breadcumb-content">
                                                <h1 className="breadcumb-title">General FAQs</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* General FAQs section start */}
                        <section className="faq-sec sec-ptb">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="sec-content text-center mb-5">
                                            <h6 className="sec-sub-title">FAQs</h6>
                                            <h2 className="sec-title">Frequently Asked Questions</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div id="accordion" className="accordion-container wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                            {[
                                                "1. Why do you need my documents while registration?",
                                                "2. How can I cancel my transfer? ",
                                                "3. Will I get notifications about Transfer status? ",
                                                "4. How many recipients I can add?",
                                                "5. You asked me to upload one more document; how can I do that? ",
                                                "6. How can I pay for my transfer? ",
                                                "7. Why I received email stating transfer complete, but the money is not with recipient?",
                                                "8. Good to know ",
                                                "9. Documents Required"
                                            ].map((title, index) => (
                                                <div key={index}>
                                                    <h4
                                                        className={`accordion-title ${activeIndex === index ? 'open' : ''}`}
                                                        onClick={() => handleAccordionClick(index)}
                                                    >
                                                        {title}
                                                    </h4>
                                                    <div className="accordion-content mb-3" style={{ display: activeIndex === index ? 'block' : 'none' }}>
                                                        {index === 0 && (
                                                            <div>
                                                                <p>
                                                                    We are regulated by Financial Conduct Authority in the UK. It is our legal binding to verify your identity as a UK resident.
                                                                </p>
                                                            </div>
                                                        )}
                                                        {index === 1 && (
                                                            <div>
                                                                <p>
                                                                    Please call us or email us at earliest possible if you want to cancel your transfer. Transfer paid out to the recipient cannot be cancelled. If you have paid for your transfer and the transfer is not processed yet, then please contact us by phone and we will make necessary arrangements for refunds. We may charge you a small fee as handling/service charge and refund you the balance amount.
                                                                </p>
                                                            </div>
                                                        )}
                                                        {index === 2 && (
                                                            <p>
                                                                Yes. We send email notifications to keep you informed. You can even view the transfer status by signing in into your web account.
                                                            </p>
                                                        )}
                                                        {index === 3 && (
                                                            <div>
                                                                <p>
                                                                    There is no restriction on the number of recipients you may have on your account.
                                                                </p>
                                                            </div>
                                                        )}
                                                        {index === 4 && (
                                                            <div>
                                                                <p>
                                                                    Please login in to your account and under My Account you will find document section where you can upload the document.
                                                                </p>
                                                            </div>
                                                        )}
                                                        {index === 5 && (
                                                            <div>
                                                                <p>
                                                                    Bank Transfer to our business account and payments made by using credit or debit cards. Bank Transfer is the recommended and preferred method of payment. There may be restrictions on credit and debit card payment as well as there may be a fee charged on top.
                                                                </p>
                                                            </div>
                                                        )}
                                                        {index === 6 && (
                                                            <div>
                                                                <p>
                                                                    Our pay-out partner bank/agent may have completed the transfer process but the recipient's bank would not have displayed it in their account. Please allow couple of hours to be displayed, if the problem persists then please contact our support team. We will make sure money is with your recipient as promised.
                                                                </p>
                                                            </div>
                                                        )}
                                                        {index === 7 && (
                                                            <div>
                                                                <ul className="sec-list-item">
                                                                    <li><i className="fa-solid fa-square-check" /> Age: 18 years and above</li>
                                                                    <li><i className="fa-solid fa-square-check" /> We will verify your identity, this will not affect your credit ratings</li>
                                                                    <li><i className="fa-solid fa-square-check" /> You must be resident of the UK</li>
                                                                    <li><i className="fa-solid fa-square-check" /> Activation of account will be based on the documents you provide</li>
                                                                    <li><i className="fa-solid fa-square-check" /> Monthly sending limits may apply</li>
                                                                </ul>

                                                            </div>
                                                        )}
                                                        {index === 8 && (
                                                            <div>
                                                                <ul className="sec-list-item">
                                                                    <li><i className="fa-solid fa-square-check" /> Photo Identity Proof (Passport, EU Nationality card or UK Driving License).</li>
                                                                    <li><i className="fa-solid fa-square-check" /> Address Proof (Utility bill, Bank Statement, Council Tax bill not less than 3 months old).</li>
                                                                    <li><i className="fa-solid fa-square-check" /> Source of funds document.</li>
                                                                    <li><i className="fa-solid fa-square-check" /> Company Houses Registration (mandatory for Corporate Accounts).</li>
                                                                    <li><i className="fa-solid fa-square-check" /> Company Address Proof (mandatory for Corporate Accounts).</li>
                                                                    <li><i className="fa-solid fa-square-check" /> Company Bank Statement (mandatory for Corporate Accounts).</li>
                                                                </ul>

                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* General FAQs section end */}
                        <Footer />
                    </div>
                </div>
            )}
        </div>
    )
}

export default General_FAQs;
